.banner_wrapper {
  position: relative;
  z-index: 9;
  .banner_video {
    width: 100%;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    position: relative;
    user-select: none;
    iframe {
      background: #000;
      width: 100vw;
      height: 56.25vw;
      min-height: 100vh;
      min-width: 177.77vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.southampton_bg_img {
      background: url(../../img/southampton-bg.jpeg);
      background-attachment: fixed;
      background-size: cover;
      background-repeat: no-repeat;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .banner_text_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    p {
      margin: 0 0 30px;
    }
  }

  .Video_lightbox {
    z-index: 99;
    position: relative;
    iframe {
      width: 90%;
      height: 100%;
    }
  }
  .full_screen_btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    button {
      background: transparent !important;
      border: 0;
      display: flex;
      align-items: center;
    }
  }
  .mouse_scroll {
    position: fixed;
    left: 50%;
    bottom: 20px;
    z-index: 9;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    background: transparent;
    border: 0;
    padding: 0;
    opacity: 1;
    transition: opacity 0.5s ease;
    transform: translate(-50%, 0);

    .mouse-icon {
      width: 20px;
      height: 35px;
      border: 2px solid white;
      border-radius: 15px;
      cursor: pointer;
      text-align: center;
      margin: 0 auto 10px;

      .mouse-wheel {
        height: 6px;
        margin: 2px auto 0;
        display: block;
        width: 3px;
        background-color: white;
        border-radius: 50%;
        -webkit-animation: 1.6s ease infinite wheel-up-down;
        -moz-animation: 1.6s ease infinite wheel-up-down;
        animation: 1.6s ease infinite wheel-up-down;
      }

      @-webkit-keyframes wheel-up-down {
        0% {
          margin-top: 2px;
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        100% {
          margin-top: 20px;
          opacity: 0;
        }
      }

      @-moz-keyframes wheel-up-down {
        0% {
          margin-top: 2px;
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        100% {
          margin-top: 20px;
          opacity: 0;
        }
      }

      @keyframes wheel-up-down {
        0% {
          margin-top: 2px;
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        100% {
          margin-top: 20px;
          opacity: 0;
        }
      }
    }
  }
}

.scroll {
  .mouse_scroll {
    opacity: 0;
  }
}

.social_icon {
  margin: 30px 0 0;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 20px;
      a {
        i {
          font-size: 32px;
          color: $textWhite;
        }
        &:hover {
          i {
            color: $primary;
          }
        }
      }
    }
  }
}

.search_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  background: #fff;
  border-radius: 30px;
  .input_Wrapper {
    display: flex;
    align-items: center;
    border-radius: 30px 0 0 30px;
    overflow: hidden;
    width: 100%;
    .search_input {
      width: 100%;
      .form-control {
        padding: 11px 20px 11px 5px;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        outline: 0;
        transition: 0.5s ease;
      }
    }
    .location_icon {
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s ease;
      color: #000;
      span {
        font-size: 24px;
        transition: 0.5s ease;
      }
      &:hover {
        background: $primaryGradient;
        span {
          color: #fff;
        }
      }
    }
  }
  .submit_button {
    .btn_primary {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 9px 20px 12px;
      border: 0;
      border-radius: 0 30px 30px 0;
    }
  }
}

.search_mobile {
  display: none;
  .search_btn {
    background: transparent;
    border: 0;
    i {
      font-weight: 700;
    }
  }
}

.about_wrap {
  background: #000 url(../../img/about-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .about_title {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto 100px;
  }
  .about_box {
    text-align: center;
    max-width: 390px;
    margin: 0 auto;
    img {
      display: none;
    }
  }
}

.testimonial_wrap {
  background: url(../../img/geometric-white.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  .testimonial_inner {
    text-align: center;
    .rating {
      i {
        color: #fff;
        font-size: 34px;
        padding: 0px 10px 20px;
      }
    }
  }
}

.parties_wrapper {
  background: $bgBlack;
  .parties_title {
    text-align: center;
  }
  .parties_box {
    padding: 240px 20px 20px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    &.thiming {
      background-image: url(../../img/theming.jpeg);
    }
    &.entertainment {
      background-image: url(../../img/entertainment.jpeg);
    }
    &.dinner {
      background-image: url(../../img/dinner.jpeg);
    }
    &.dancing {
      background-image: url(../../img/dancing.jpeg);
    }
    &.dodgems {
      background-image: url(../../img/dodgems.jpeg);
    }
    &.casino {
      background-image: url(../../img/casino.jpeg);
    }
  }
}

.newsletter_wrap {
  padding: 50px 0;
  background: #06072a url(../../img/newsletter-img.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right -220px center;
}

.newsletter_form {
  .form-group {
    .form-control {
      background: transparent !important;
      border-radius: 4px;
      border: solid 1px #979797;
      color: #fff !important;
      font-size: 20px;
      height: 51px;
      font-style: italic;
      box-shadow: none !important;
      &:focus {
        border: solid 1px #979797;
      }
    }
  }
}

.newsletter_form .form-group {
  margin: 0 0 20px;
}

.location_sidebar.p-sidebar {
  background: #000;
  .p-sidebar-header {
    padding: 0;
    .p-sidebar-close {
      box-shadow: none !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  .p-sidebar-content {
    padding-top: 30px;
    ul.nav-pills {
      li {
        span {
          font-size: 16px;
          font-weight: 900;
          margin: 0px 15px 0px 15px;
          padding: 5px 0px 15px 0px;
          border-bottom: 3px solid transparent;
          border-right: none;
          color: #fff;
          text-transform: uppercase;
          background: transparent;
          border-radius: 0;
          cursor: pointer;
          &.active {
            color: $primary;
            border-bottom: 3px solid $primary;
          }
        }
      }
    }
  }
}

.location_box_inner {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.location_box {
  display: flex;
  padding: 10px;
  border-bottom: solid 1px #272727;
  cursor: pointer;
  .location_img {
    width: 290px;
  }
  .Location_content {
    width: calc(100% - 290px);
    padding-left: 20px;
    position: relative;
    padding-top: 10px;
    h6 {
      font-weight: 900;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      font-family: 'Figtree';
    }
    span {
      color: $primary;
      display: inline-flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: 'Figtree';
      font-weight: 600;
      i {
        padding-top: 5px;
      }
    }
  }
  &:hover {
    background: $primaryGradient;
    .Location_content {
      span {
        color: $textWhite;
      }
    }
  }
}

.map_wrapper {
  height: calc(100vh - 100px);
}

.banner_wrapper.exclusive_banner {
  .exclusive_banner_text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    top: 300px;
    z-index: 9;
    p {
      margin: 0 0 45px;
    }
  }
}

.exclusive_partie_wrapper {
  background: #000;
}

.booking_start_wrapper {
  p {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    font-family: 'Figtree';
  }
}

.know_more_wrap {
  p {
    font-size: 20px;
    line-height: 24px;
  }
}

section.height-auto {
  height: auto !important;
}

.inner_banner {
  text-align: center;
  min-height: 350px;
  padding: 150px 0 40px;
  &.login_banner {
    background: url(../../img/login-bg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.contact_banner {
    background: url(../../img/static-page-bg3.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.one_feeds_two__banner {
    background: url(../../img/one_feeds_two.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.login_wrapper {
  .login_inner {
    .login_form {
      max-width: 300px;
      margin: 0 auto 50px;
      h3 {
        text-align: center;
        color: $textBlack;
        margin: 0 0 20px;
      }

      .forgot_pass {
        text-align: center;
        a {
          color: $primary;
          font-weight: 300;
          text-decoration: underline;
        }
      }
    }
  }
  .dont_have_account_wrap {
    h2 {
      color: $textBlack;
      font-weight: 500;
      margin: 0 0 10px;
    }
    p {
      color: $textGray;
      max-width: 350px;
      margin: 0 auto 20px;
    }
  }
}

.register_inner_wrapper {
  .register_form {
    max-width: 730px;
    margin: 0 auto;
    form {
      margin-bottom: 50px;
    }
    .address_lookup_wrap {
      h2 {
        color: $textBlack;
        font-weight: 500;
      }
      p {
        small {
          color: $textGray;
          line-height: 20px;
          display: block;
        }
      }
    }
  }
}

.form-group {
  .form-control {
    font-style: italic;
    box-shadow: none !important;
    outline: 0;
    font-size: 14px;
    padding: 8px 20px;
    &:focus {
      border-color: #000;
    }
  }
  .form-label {
    font-size: 15px;
    line-height: 18px;
    padding: 7px 0;
    font-weight: normal;
    margin: 0 !important;
    color: $textBlack;
  }
  .form-select {
    font-style: italic;
    box-shadow: none !important;
    outline: 0;
    font-size: 14px;
    padding: 8px 33px 8px 20px;
    &:focus {
      border-color: #000;
    }
  }
  span.note {
    display: block;
    color: $orange;
  }
}

.form-check {
  margin: 0 0 15px;
  .form-check-label {
    font-size: 14px;
    font-weight: normal;
    color: $textBlack;
  }
  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
    &:focus {
      border-color: #ff2a3e;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(255 0 12 / 25%);
    }
  }
}

.setup_account_wrap {
  h2 {
    color: $textBlack;
    text-align: center;
    margin: 0 0 30px;
  }
  p {
    text-align: center;
    color: $textGray;
  }
  .form-group {
    max-width: 300px;
    margin: 0 auto;
  }
  .btn_primary {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
  }
}

.forgot_wrap {
  h2 {
    text-align: center;
    color: $textBlack;
    margin: 0 0 30px;
  }
  .forgot_form {
    max-width: 300px;
    margin: 0 auto;
  }
}

.account_wrap_inner {
  p {
    color: $textGray;
    a {
      color: $primary;
      text-decoration: underline;
    }
    span.primary_btn {
      background: $primary;
      color: #fff;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 5px;
      margin: 0 3px;
    }
  }
  .account_top_content {
    margin: 0 0 50px;
  }
  .your_booking_wrapper {
    h2 {
      color: $textBlack;
      font-weight: 500;
      margin: 0 0 15px;
    }
  }
}

.table_design_one {
  table {
    thead {
      tr {
        th {
          background: #eeeeee;
          font-size: 18px;
          font-weight: 600;
          width: 20%;
        }
      }
    }
  }
}
.table_design_two {
  &.bacs {
    table {
      thead {
        tr {
          th {
            background-color: #fff;
          }
        }
      }
    }
  }
  table {
    thead {
      tr {
        th {
          background: rgba(0, 0, 0, 0.2);
          color: #3b3b3b;
          padding: 10px 15px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          padding: 10px 15px;
          vertical-align: middle;

          background: #fff;
          a {
            color: $primary;
            text-decoration: underline;
          }
          p.no_data {
            text-align: center;
            font-style: italic;
            font-size: 14px;
            margin: 0;
          }
        }
        &:nth-child(odd) {
          td {
            background: #f4f4f4;
          }
        }
      }
    }
  }
}

// .custom_html_wrap table tbody tr td.custom_td:has(.custom_html) {
//   background: $bgBlack !important;
//   color: $bgWhite !important;
//   width: 100% !important;
//   max-width: 700px !important;
// }

.custom_html_wrap table tbody tr td.custom_td:has(.custom_html) table tbody td {
  background: $bgBlack !important;
  color: $bgWhite !important;
  // width: 100% !important;
  // max-width: 700px !important;
}

.custom_html {
  width: 100% !important;
  max-width: 750px !important;
  background: $bgBlack !important;
  color: $bgWhite !important;
  table {
    width: 100% !important;
    p {
      max-width: 600px;
    }
  }
}

.booking_wrap_inner {
  p {
    color: $textGray;
  }
  .back_to_booking {
    margin: 0 0 15px;
    .btn_primary {
      text-transform: capitalize;
      i {
        font-size: 20px;
      }
    }
  }
  .top_heading {
    h2 {
      color: $textBlack;
      margin: 0 0 20px;
    }
    p {
      color: #999;
      font-size: 18px;
      span {
        color: $textBlack;
      }
    }
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: $primary;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: $primary !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-panels {
  padding: 1.25rem 0;
}

.filter_button_right {
  ul {
    display: flex;
    align-items: center;
    justify-content: end;
    li {
      margin-left: 10px;
      .btn_primary {
        text-transform: capitalize;
        height: 42px;
      }
    }
  }
}

.guest_total_wrap {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      width: 25%;
      font-size: 14px;
      font-weight: 400;
      color: $textGray;
      span {
        color: $textBlack;
        font-weight: 700;
      }
    }
  }
}

.import_gueast_wrap,
.search_for_guest_wrap,
.filter_guest_wrap,
.add_new_guest_wrap,
.import_prev_guest_wrap,
.select_email_template {
  margin-bottom: 20px;
  border: 1px solid #a9a9a9;

  .download_Wrap_right {
    padding: 15px;
  }
}

.table_header_wrap {
  background: rgba(0, 0, 0, 0.2);
  color: #3b3b3b;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 500;
  }
  .btn_primary.small {
    padding: 8px 10px;
  }
}

.filter_guest_inner {
  h4 {
    color: $textBlack;
    font-weight: 500;
    font-size: 18px;
  }
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    li {
      width: calc(100% / 6 - 20px);
      button {
        width: 100%;
        i {
          font-weight: 700;
        }
      }
    }
  }
}

.note_wrapper {
  p {
    font-size: 14px;
    line-height: 18px;
    color: $textBlack;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
    li {
      font-size: 12px;
      margin: 0 0 10px;
    }
  }
}

.guest_attending_wrap {
  h4 {
    color: $textBlack;
  }
}

.guest_table_wrapper {
  table {
    thead {
      tr {
        th {
          background: $primary;
          color: #fff;
          font-size: 16px;
          width: auto;
        }
      }
    }
    tbody {
      tr {
        td {
          cursor: pointer;
        }
        &.expanded {
          td {
            padding: 0;
            .add_new_guest_wrap {
              margin-bottom: 0 !important;
              background: #fff;
            }
          }
          &:hover {
            td {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
table {
  .checkbox_wrapper .form-check {
    margin: 0;
  }
}

.booking_tab_wrapper .location_content * {
  color: $textBlack;
}

.booking_wrap_inner .hire_exclusive p {
  color: #fff;
}

.p-tabview .p-tabview-panels {
  color: inherit;
}

.booking_tab_wrapper {
  .location_section {
    padding: 20px 0 0;
    .location_title {
      h2 {
        color: $textBlack;
      }
      h3 {
        color: $textBlack;
      }
    }
  }
  .testimonial_wrap {
    display: none;
  }
  .drink_wrapper {
    padding: 20px 0 0;
    .text-center h4 {
      color: $textBlack;
      text-align: left;
    }
    .text-center h4 ~ p {
      text-align: left;
    }
    .text-center h3 {
      color: $textBlack;
      text-align: center;
    }
    h2 {
      color: $textBlack;
    }
    .drink_list_wrapper {
      .view_drink_list {
        span {
          text-shadow: none;
          box-shadow: none;
          color: $primary;
          border-color: $primary;
          font-size: 15px;
          &:after {
            border-top-color: $primary;
          }
        }
      }
    }
  }
  .account_gallery_wrapper {
    .gallery_wrapper {
      .Gallery_image_wrapper {
        gap: 10px;
        .gallery-item {
          width: calc(100% / 6 - 10px);
          height: 200px;
        }
      }
    }
  }
}

.help_wrapper {
  .top_title {
    p {
      a {
        color: $primary;
      }
    }
  }
}

.accordian_wrapper {
  .accordion {
    .accordion-item {
      margin: 0 0 10px;
      .accordion-header {
        .accordion-button {
          background: #f4f4f4;
          line-height: 20px;
          color: $primary;
          &:after {
            color: $primary;
            filter: brightness(0);
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
      &:not(:first-of-type) {
        border-top: 1px solid #ddd;
      }
      .accordion-collapse {
        .accordion-body {
          p {
            font-size: 14px;
          }
          ul {
            list-style: disc;
            padding-left: 20px;
            li {
              h6 {
                color: $textBlack;
              }
            }
          }
        }
      }
    }
  }
}

.request_brochure_inner {
  max-width: 1000px;
  margin: 0 auto;
  p {
    color: $textGray;
  }
}
.contact_wrapper_innrr {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    li {
      width: calc(33.33% - 30px);
      padding: 0 15px;
      margin-bottom: 10px;
      position: relative;
      &:after {
        content: '\f0da';
        font-family: 'FontAwesome';
        font-size: 15px;
        line-height: 0px;
        color: $primary;
        position: absolute;
        left: 0;
        top: 13px;
        font-weight: bold;
      }
    }
  }
}

.contact_wrapper_inner {
  h2 {
    margin: 0 0 30px;
    color: $textBlack;
  }
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong,
  ul,
  li,
  ol {
    color: $textBlack;
  }
  p {
    color: $textBlack;
    span {
      font-size: 24px;
      font-weight: 700;
    }
    a {
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.vacancies_inner {
  p {
    color: $textBlack;
    a {
      color: $primary;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.sustainability_inner {
  p {
    color: $textBlack;
  }
  ul.list_ul {
    li {
      color: $textBlack;
      a {
        color: $primary;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}

.privacy_inner,
.feedback_inner {
  p {
    color: $textBlack;
    a {
      color: $primary;
      display: inline-block;
      padding: 0 5px;
      &:hover {
        color: $orange;
      }
    }
  }
  hr {
    border-color: #000;
  }
}
.booking_term_inner {
  p {
    color: $textBlack;
  }
  ul {
    list-style: decimal;
    padding-left: 15px;
    li {
      color: $textBlack;
      font-weight: 600;
      font-size: 18px;
      ul {
        padding-left: 15px;
        list-style: lower-alpha;
        margin: 10px 0 0;
        li {
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 10px;
        }
      }
    }
  }
}

.exclusive_form_inner {
  h3 {
    color: #9d925c;
    font-size: 40px;
    font-weight: 500;
    margin: 0 0 25px;
    line-height: 44px;
  }
  h4 {
    font-size: 30px;
    font-weight: 400;
    color: #9d925c;
    margin: 0 0 30px;
    line-height: 36px;
  }
  p {
    color: $textBlack;
    a {
      color: $primary;
      &:hover {
        color: $orange;
      }
    }
  }
  ul.list_ul {
    li {
      color: $textBlack;
      i {
        padding-right: 5px;
      }
    }
  }
  .welcome_part_exclusive_form,
  .exclusive_form_button {
    padding-bottom: 60px;
    margin-bottom: 60px;
    border-bottom: 1px solid #000;
  }
}
.testimonial_slider {
  .swiper {
    padding-bottom: 30px;
    .testimonial_slide_wrap {
      text-align: center;
      img {
        max-height: 100px;
        margin: 0 0 20px;
      }
      p {
        max-width: 450px;
        font-weight: 300;
        margin: 0 auto;
      }
    }
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet-active {
        background: $primary;
      }
    }
  }
}

.availability_popup {
  box-shadow: 0px 0px 5px #666;
  margin-top: 130px !important;
  .p-dialog-header {
    .p-dialog-title {
      width: 100%;
      text-align: center;
      border: 0;
      padding: 0;
      color: #fff;
      font-size: 26px;
      letter-spacing: 1px;
    }
    .p-dialog-header-icons {
      .p-dialog-header-icon {
        position: absolute;
        right: -20px;
        top: -20px;
        border: 2px solid #fff;
        width: 50px;
        height: 50px;
        background: #000;
        opacity: 1;
        color: #fff;
      }
    }
  }
  .check_availability_wrap {
    p {
      font-size: 20px;
      text-align: center;
      line-height: 30px;
    }
    .form-group {
      text-align: center;
      .form-label {
        color: #fff;
      }
      .form-control {
        max-width: 120px;
        margin: 0 auto;
      }
      small {
        color: #fff;
      }
    }
    .place_reserved_Wrap {
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.map_wrapper {
  #map {
    height: 100%;
  }
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled {
  color: #fff;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff;
  border-color: #404448;
  background: $grayGradient;
}

.account_edit_wrap {
  p {
    color: $textBlack;
    span {
      font-weight: 300;
    }
    small {
      font-weight: 300;
    }
  }
}

.one_feed_two_content {
  text-align: center;
  p {
    color: $textBlack;
  }
}

.lookup_data_wrap {
  padding: 10px;
  border: 1px solid $primary;
  border-radius: 10px;
  margin-bottom: 15px;
  .lookup_data_inner {
    max-height: 250px;
    overflow: auto;
    padding-right: 10px;
    .lookup_data {
      font-size: 12px;
      line-height: 14px;
      padding: 5px 8px;
      border-radius: 6px;
      border: 1px solid #f1f1f1;
      margin-bottom: 8px;
      cursor: pointer;
      width: 100%;
      text-align: start;
      background: transparent;
      transition: 0.5s ease;
      &:hover,
      &:focus {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}

.book_places_wrap {
  h2 {
    font-weight: 400;
    span {
      background: $primary;
      padding: 5px;
      display: inline-block;
      color: $textWhite;
    }
  }
}
.book_places_form_wrap {
  max-width: 750px;
  margin: 0 auto;
}

.file-uploded {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.p-fileupload-row.file-uploded-wrap {
  width: 100% !important;
}

.file-inner-wrap {
  text-align: left;
}

.file-clos-btn button {
  height: 100% !important;
  padding: 0 !important;
}

.guest_table_wrapper .table tr.highlight td {
  background-color: rgb(253 209 199);
  color: #000;
}

.menu_tab_wrap ul.list_ul li,
.menu_tab_wrap ul li {
  color: $textBlack;
}
// .menu_tab_wrap .menu_content:nth-child(2) {
.menu_tab_wrap {
  .menu_content {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem !important;
      &:nth-child(2n - 1) {
        display: block;
        margin-bottom: 0 !important;
      }
      li {
        margin-right: 20px;
        padding: 10px 0 10px 16px;
      }
    }
  }
}

.menu_tab_wrap .menu_content h4 {
  color: $textBlack;
}

.booking_wrap_inner .menu_tab_wrap p {
  color: $textBlack;
}

.badge.badge_primary {
  background-color: $primary;
  color: $bgWhite !important;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 18px;
}

.confirm_table_inner {
  background: #f4f4f4;
  ul {
    li {
      display: flex;
      padding: 5px;
      border-bottom: 1px solid #d8d8d8;
      p {
        margin: 0;
      }
      &:nth-child(odd) {
        background: $bgWhite;
      }
    }
  }
}

.delete_popup {
  .p-dialog-header {
    background: $bgWhite;
    border-bottom: 0;
    padding: 5px;
  }
  .p-dialog-content {
    background: $bgWhite;
    p {
      color: $textBlack;
    }
  }
}

.delete_popup .p-dialog-header .p-dialog-header-icon:enabled {
  color: #000;
}

.delete_popup .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff;
  border-color: $primary;
  background: $primary;
}

.delete_popup .btn_border {
  border-width: 1px;
}

.delete_popup.p-dialog .p-dialog-header .p-dialog-title {
  border: none;
  padding: 0;
}

.delete_popup .delete_modal_wrap {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 50px;
}

.p-dialog {
  margin: 0 15px;
}

.contact_modal_wrap.enquires {
  height: 500px;
  overflow: auto;
}

.enquires {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    li {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      a {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #aaaaaa;
        font-size: 10px;
        i {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $bgWhite;
          font-size: 13px;
        }
      }
      &.facebook {
        a {
          i {
            background: #3b5998;
          }
        }
      }
      &.instagram {
        a {
          i {
            background: #c32aa3;
          }
        }
      }
      &.twitter {
        a {
          i {
            background: #1da1f2;
          }
        }
      }
      &.linkedin {
        a {
          i {
            background: #0a66c2;
          }
        }
      }
    }
  }
}

.book_place_text_wrap p {
  max-width: 500px;
  margin: 20px auto 20px;
}

.order_products_table_wrapper {
  .order_products_table {
    overflow-x: auto;
    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      min-width: 600px;
      thead {
        tr {
          th {
            font-weight: 600;
            color: #333;
            border: 0;
          }
        }
      }
      tbody {
        tr {
          td {
            border: 0;
            &.order_heading {
              background: $primary;
              color: #fff;
            }
            p {
              max-width: 500px;
            }
            span.entertain_note {
              color: #fa6851;
            }
            .input_Wrapper {
              .form-control {
                box-shadow: none;
              }
            }
          }
        }
      }
      tfoot {
        tr {
          td {
            border: 0;
          }
          &.cart_total_wrap {
            td {
              background: rgba(0, 0, 0, 0.2);
              color: #3b3b3b;
            }
            &.grand_total_Wrap {
              td {
                &:last-child {
                  background: #fa6851;
                  color: #fff;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}

.place_change_input_wrapper {
  .place_change_input_head {
    display: flex;
    align-items: center;
    .form-group {
      margin-left: 15px;
      max-width: 100px;
      .form-control {
        box-shadow: none;
      }
    }
  }
}

.balance_table_Wraper {
  border: 1px solid #ddd;
  table {
    margin: 0;
    border: 1px solid #ddd;
    tbody {
      tr {
        td {
          color: #06072a;
          font-size: 16px;
          padding: 7px 20px;
          &:first-child {
            text-align: right;
          }
          &:last-child {
            color: #3b3b3b;
            font-size: 14px;
            span {
              color: #06072a;
              font-size: 16px;
            }
          }
          &.total_wrapper {
            font-size: 20px;
            background: #fa6846 !important;
          }
        }
        &:nth-child(odd) {
          td {
            background: #f4f4f4;
          }
        }
      }
    }
  }
}

.black_box_wrap {
  padding: 20px 30px;
  background: $grayGradient;
  color: $textWhite;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  text-align: center;
  margin: 30px 0;
}

.pi-check.green:before {
  color: #0c0;
}
