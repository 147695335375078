// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProSemiBold.eot');
//   src: local('Sofia Pro Semi Bold'), local('SofiaProSemiBold'),
//     url('../../fonts/SofiaPro/SofiaProSemiBold.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProSemiBold.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProSemiBold.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProSemiBold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProLight.eot');
//   src: local('Sofia Pro Light'), local('SofiaProLight'),
//     url('../../fonts/SofiaPro/SofiaProLight.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProLight.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProLight.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProLight.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProExtraLight.eot');
//   src: local('Sofia Pro ExtraLight'), local('SofiaProExtraLight'),
//     url('../../fonts/SofiaPro/SofiaProExtraLight.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProExtraLight.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProExtraLight.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProExtraLight.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProRegular.eot');
//   src: local('Sofia Pro Regular'), local('SofiaProRegular'),
//     url('../../fonts/SofiaPro/SofiaProRegular.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProRegular.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProRegular.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProRegular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProMedium.eot');
//   src: local('Sofia Pro Medium'), local('SofiaProMedium'),
//     url('../../fonts/SofiaPro/SofiaProMedium.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProMedium.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProMedium.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProMedium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProBold.eot');
//   src: local('Sofia Pro Bold'), local('SofiaProBold'),
//     url('../../fonts/SofiaPro/SofiaProBold.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProBold.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProBold.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProBold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProBlack.eot');
//   src: local('Sofia Pro Black'), local('SofiaProBlack'),
//     url('../../fonts/SofiaPro/SofiaProBlack.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProBlack.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProBlack.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProBlack.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Figtree';
//   src: url('../../fonts/SofiaPro/SofiaProUltraLight.eot');
//   src: local('Sofia Pro UltraLight'), local('SofiaProUltraLight'),
//     url('../../fonts/SofiaPro/SofiaProUltraLight.eot?#iefix')
//       format('embedded-opentype'),
//     url('../../fonts/SofiaPro/SofiaProUltraLight.woff2') format('woff2'),
//     url('../../fonts/SofiaPro/SofiaProUltraLight.woff') format('woff'),
//     url('../../fonts/SofiaPro/SofiaProUltraLight.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-SemiBold.eot');
  src: url('../../fonts/Figtree/Figtree-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-SemiBold.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-SemiBold.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-SemiBold.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-SemiBold.svg#Figtree-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Italic.eot');
  src: url('../../fonts/Figtree/Figtree-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Italic.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Italic.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Italic.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Italic.svg#Figtree-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Bold.eot');
  src: url('../../fonts/Figtree/Figtree-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Bold.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Bold.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Bold.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Bold.svg#Figtree-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-ExtraBold.eot');
  src: url('../../fonts/Figtree/Figtree-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-ExtraBold.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-ExtraBold.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-ExtraBold.svg#Figtree-ExtraBold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Regular.eot');
  src: url('../../fonts/Figtree/Figtree-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Regular.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Regular.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Regular.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Regular.svg#Figtree-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Light.eot');
  src: url('../../fonts/Figtree/Figtree-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Light.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Light.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Light.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Light.svg#Figtree-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Black.eot');
  src: url('../../fonts/Figtree/Figtree-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Black.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Black.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Black.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Black.svg#Figtree-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('../../fonts/Figtree/Figtree-Medium.eot');
  src: url('../../fonts/Figtree/Figtree-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Figtree/Figtree-Medium.woff2') format('woff2'),
    url('../../fonts/Figtree/Figtree-Medium.woff') format('woff'),
    url('../../fonts/Figtree/Figtree-Medium.ttf') format('truetype'),
    url('../../fonts/Figtree/Figtree-Medium.svg#Figtree-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../../fonts/Afacad/Afacad-Bold.eot');
  src: url('../../fonts/Afacad/Afacad-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Afacad/Afacad-Bold.woff2') format('woff2'),
    url('../../fonts/Afacad/Afacad-Bold.woff') format('woff'),
    url('../../fonts/Afacad/Afacad-Bold.ttf') format('truetype'),
    url('../../fonts/Afacad/Afacad-Bold.svg#Afacad-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../../fonts/Afacad/Afacad-SemiBold.eot');
  src: url('../../fonts/Afacad/Afacad-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Afacad/Afacad-SemiBold.woff2') format('woff2'),
    url('../../fonts/Afacad/Afacad-SemiBold.woff') format('woff'),
    url('../../fonts/Afacad/Afacad-SemiBold.ttf') format('truetype'),
    url('../../fonts/Afacad/Afacad-SemiBold.svg#Afacad-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../../fonts/Afacad/Afacad-Italic.eot');
  src: url('../../fonts/Afacad/Afacad-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Afacad/Afacad-Italic.woff2') format('woff2'),
    url('../../fonts/Afacad/Afacad-Italic.woff') format('woff'),
    url('../../fonts/Afacad/Afacad-Italic.ttf') format('truetype'),
    url('../../fonts/Afacad/Afacad-Italic.svg#Afacad-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../../fonts/Afacad/Afacad-Regular.eot');
  src: url('../../fonts/Afacad/Afacad-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Afacad/Afacad-Regular.woff2') format('woff2'),
    url('../../fonts/Afacad/Afacad-Regular.woff') format('woff'),
    url('../../fonts/Afacad/Afacad-Regular.ttf') format('truetype'),
    url('../../fonts/Afacad/Afacad-Regular.svg#Afacad-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../../fonts/Afacad/Afacad-Medium.eot');
  src: url('../../fonts/Afacad/Afacad-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Afacad/Afacad-Medium.woff2') format('woff2'),
    url('../../fonts/Afacad/Afacad-Medium.woff') format('woff'),
    url('../../fonts/Afacad/Afacad-Medium.ttf') format('truetype'),
    url('../../fonts/Afacad/Afacad-Medium.svg#Afacad-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$primary: #ff7131;
$primaryGradient: linear-gradient(to right, #872b95 0%, #ff7131 100%);
$blueGradient: linear-gradient(to bottom, #030118 0%, #06072a 100%);
$orangeGradient: linear-gradient(to bottom, #ec3f38, #ec4324);
$grayGradient: linear-gradient(#404448, #4e5458 40%, #53595d);
$greenGradient: linear-gradient(#77cc77, #56bf56 40%, #56bf56);
$orange: #ec4324;
$textWhite: #eeeeee;
$bgWhite: #ffffff;
$textBlack: #000000;
$bgBlack: #000000;
$textGray: #7d7d7d;
$sifiaPro: 'Sofia Pro';
$sourceSans: 'Source Sans Pro';

::-moz-selection {
  background: $primary;
  color: #fff;
}

::-webkit-selection {
  background: $primary;
  color: #fff;
}
::selection {
  background: $primary;
  color: #fff;
}

body {
  font-family: 'Figtree';
  // -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #eeeeee !important;
  background: #fff !important;
  overflow-x: hidden;
}

p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: #eeeeee;
  margin: 0 0 15px;
}

p.big {
  font-size: 28px;
  line-height: 31px;
  font-weight: 500;
  color: $textWhite;
  font-family: 'Figtree';
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
}

a:hover {
  color: $orange;
}

img {
  max-width: 100%;
}

body.modal_open {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Afacad';
}

h1,
.h1 {
  font-size: 70px;
  line-height: 84px;
  color: $textWhite;
  font-weight: 900;
}

h2,
.h2 {
  font-size: 42px;
  line-height: 50px;
  color: $textWhite;
  font-weight: 900;
}
h3,
.h3 {
  font-size: 28px;
  line-height: 31px;
  color: $textWhite;
  font-weight: 900;
}
h4,
.h4 {
  font-size: 20px;
  line-height: 24px;
  color: $textWhite;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 22px;
  color: $textWhite;
  font-weight: 600;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 20px;
  color: $textWhite;
  font-weight: 600;
}

.fw_900 {
  font-weight: 900;
}
.fw_700 {
  font-weight: 700;
}
.fw_600 {
  font-weight: 600;
}
.fw_500 {
  font-weight: 500;
}
.fw_400 {
  font-weight: 400;
}
.fw_300 {
  font-weight: 300;
}

.text_light {
  color: $textGray !important;
}
.text_dark {
  color: $textBlack !important;
}
.text_primary {
  color: $primary !important;
}
.text_orange {
  color: $orange !important;
}
.text_white {
  color: $textWhite !important;
}

.text_small {
  font-size: 80% !important;
}
.text_big {
  font-size: 120% !important;
}

.bg_primary {
  background: $primary !important;
}
.bg_grey {
  background: $textGray;
}
.text_white {
  color: $bgWhite;
}

.cursor_pointer {
  cursor: pointer;
}

.pt-120 {
  padding-top: 120px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
