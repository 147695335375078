header {
  padding: 0;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1002;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  &:hover {
    background: #000;
  }
  &.menu_collapse {
    background: #000 !important;
  }
  .left_hedaer {
    display: flex;
    align-items: center;
    .logo_wrap {
      img {
        max-height: 60px;
      }
    }
    .menu_toggle {
      padding: 35px;
      margin-right: 30px;
      cursor: pointer;
      transition: 0.5s ease;
      &:hover {
        background: $primary;
      }
      i {
        font-size: 30px;
        color: #fff;
      }
    }
  }
  .right_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 35px;
      li {
        padding-left: 30px;
        a.call_link {
          font-size: 28px;
          text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
          color: #fff;
          font-weight: 700;
          font-family: 'Figtree';
          &:hover {
            color: $primary;
          }
        }
        .btn_border,
        .btn_border_white {
          font-weight: 600;
          padding: 6px 30px;
          font-size: 18px;
        }
      }
    }
  }

  .menu_wrapper {
    background: rgba(0, 0, 0, 0.9);
    height: calc(100vh - 104px);
    position: absolute;
    top: 104px;
    left: 0;
    width: 100%;
    z-index: -1;
    padding: 50px;
    transition: 0.5s ease;
    .menu_inner {
      height: 75%;
      overflow: auto;
      .p-slidemenu {
        width: 560px;
        background: transparent;
        border: 0;
        height: 100%;
        .p-slidemenu-wrapper {
          height: 100% !important;
          padding-top: 60px;
          .p-slidemenu-backward {
            top: 0;
            bottom: inherit;
            padding: 0;
            color: #fff;
          }
        }
        .p-slidemenu-content {
          .p-slidemenu-rootlist {
            width: 100% !important;
            .p-submenu-list {
              width: 100% !important;
            }
            .p-menuitem {
              .p-menuitem-link {
                font-size: 24px;
                line-height: 26px;
                text-transform: uppercase;
                font-weight: 500;
                padding: 20px 0;
                box-shadow: none !important;
                &:hover {
                  background: transparent;
                  color: #fff;
                  > span.p-menuitem-text {
                    text-decoration: underline;
                  }
                }
                span {
                  color: #fff;
                }
              }
              &.clickabel_menu {
                .p-menuitem-link {
                  font-size: 18px;
                  line-height: 20px;
                  font-weight: 500;
                  padding: 15px 0;
                  box-shadow: none !important;
                  &:hover {
                    background: transparent;
                    color: #fff;
                    > span.p-menuitem-text {
                      text-decoration: underline;
                    }
                  }
                  span {
                    color: #fff;
                  }
                }
              }
              &.about_menu {
                > .p-menuitem-link {
                  > .p-menuitem-text {
                    font-size: 24px;
                    line-height: 26px;
                    text-transform: uppercase;
                    font-weight: 500;
                  }
                }
              }
              &.menu_link {
                .p-menuitem-link {
                  font-size: 15px;
                  line-height: 21px;
                  font-weight: 400;
                  padding: 8px 0;
                  position: relative;
                  box-shadow: none !important;
                  text-transform: capitalize;
                  &:hover {
                    background: transparent;
                    color: #fff;
                    > span.p-menuitem-text {
                      text-decoration: underline;
                    }
                  }
                  span {
                    color: #fff;
                  }
                  span.p-menuitem-icon {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottom_menu {
      ul {
        li {
          margin: 0 0 10px;
          a {
            font-size: 20px;
            line-height: 29px;
            font-weight: 400;
            color: $primary;
            &:hover {
              text-decoration: underline;
            }
            i {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  &.fixed {
    background: #000;
    background-repeat: no-repeat;
    .left_hedaer {
      .menu_toggle {
        padding: 15px;
      }
      .logo_wrap img {
        max-height: 40px;
      }
    }
    .menu_wrapper {
      height: calc(100vh - 64px);
      top: 64px;
    }
    .btn_border,
    .btn_border_white {
      padding: 4px 20px !important;
      font-size: 16px !important;
    }
    .search_box {
      max-width: 360px;
      .input_Wrapper {
        .location_icon {
          padding: 10px 10px;
          span {
            font-size: 20px;
          }
        }
        .search_input {
          .form-control {
            padding: 7px 20px 7px 5px;
          }
        }
      }
      .submit_button {
        .btn_primary {
          font-size: 16px;
          padding: 7px 17px 9px;
        }
      }
    }
  }
}
